import React from 'react'

const StickyHeader = () => {
  return (
    <header id="sticky-site-header" className="site-header custom-sticky-header overlap-header is-sticky hide-header">
			{/* <link rel="stylesheet" id="elementor-post-1194-css" href="./assets/css/post-1194.css" type="text/css"
				media="all" /> */}
			<div data-elementor-type="wp-post" data-elementor-id="1194" className="elementor elementor-1194">
				<div className="elementor-inner">
					<div className="elementor-section-wrap">
						<section
							className="elementor-section elementor-top-section elementor-element elementor-element-1827a322 elementor-section-content-middle elementor-hidden-tablet elementor-hidden-mobile elementor-section-full_width elementor-section-height-default elementor-section-height-default"
							data-id="1827a322" data-element_type="section"
							data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
							<div className="elementor-container elementor-column-gap-no">
								<div className="elementor-row">
									<div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-3d341673 dark-color"
										data-id="3d341673" data-element_type="column">
										<div className="elementor-column-wrap elementor-element-populated">
											<div className="elementor-widget-wrap">
												<div className="elementor-element elementor-element-1e2a7daa elementor-widget__width-auto elementor-widget elementor-widget-cs_menu_toggle"
													data-id="1e2a7daa" data-element_type="widget"
													data-widget_type="cs_menu_toggle.default">
													<div className="elementor-widget-container">
														<button className="menu-toggle elementor-widget-menu-toggle">
															<span className="screen-reader-text">Menu</span>
															<span className="menu-toggle-icon"></span>
														</button>
													</div>
												</div>
												<div className="elementor-element elementor-element-79543eae elementor-widget__width-auto elementor-widget elementor-widget-cs_menu"
													data-id="79543eae" data-element_type="widget"
													data-widget_type="cs_menu.default">
													<div className="elementor-widget-container">
														<nav
															className="cs-menu main-navigation not-mobile-menu dropdown-dark">
															<ul id="menu-79543eae" className="menu primary-menu">
																<li
																	className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-9 current_page_item 
																	">
																	<a href=""
																		aria-current="page"><span>Home</span></a><button
																		className="dropdown-toggle"
																		aria-expanded="false"><span
																			className="screen-reader-text">expand child
																			menu</span></button>
																</li>
																<li
																	className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1082">
																	<a href="#"><span>About Us</span></a>
																</li>
																<li
																	className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-41">
																	<a href="#"><span>Services</span></a>
																</li>
																<li
																	className="menu-item menu-item-type-post_type menu-item-object-page menu-item-31">
																	<a href="blog/"><span>Contact</span></a>
																</li>
															</ul>
														</nav>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-15208836 dark-color"
										data-id="15208836" data-element_type="column">
										<div className="elementor-column-wrap elementor-element-populated">
											<div className="elementor-widget-wrap">
												<div className="elementor-element elementor-element-ecaaa3e elementor-align-center elementor-widget elementor-widget-cs_logo"
													data-id="ecaaa3e" data-element_type="widget"
													data-widget_type="cs_logo.default">
													<div className="elementor-widget-container">
														<a href="">
															<img width="255" height="51"
																src="/images/logo.png"
																className="attachment-cozystay_255x9999 size-cozystay_255x9999 wp-image-184"
																alt=""
																sizes="(max-width: 255px) 100vw, 255px"/> </a>
																
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-60b6b98c dark-color"
										data-id="60b6b98c" data-element_type="column">
										<div className="elementor-column-wrap elementor-element-populated">
											<div className="elementor-widget-wrap">
												<div className="elementor-element elementor-element-5732c3b6 elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
													data-id="5732c3b6" data-element_type="widget"
													data-widget_type="text-editor.default">
													<div className="elementor-widget-container">
														<div className="elementor-text-editor elementor-clearfix">
															<a href="tel:+13473654373">Tel: +1 347 365 4373</a>
														</div>
													</div>
												</div>
												<div className="elementor-element elementor-element-538ee816 elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
													data-id="538ee816" data-element_type="widget"
													data-widget_type="text-editor.default">
													 {/* <div className="elementor-widget-container">
														<div className="elementor-text-editor elementor-clearfix">
															<a href="#">EN</a>
															/ <a href="#">FR</a>
														</div>
													</div>  */}
												</div>
												<div className="elementor-element elementor-element-2d1c712b elementor-widget__width-auto elementor-widget elementor-widget-cs_button"
													data-id="2d1c712b" data-element_type="widget"
													data-widget_type="cs_button.default">
													<div className="elementor-widget-container">
														<a href="#"
															className="elementor-button-link button cs-btn-outline cs-btn-small cs-btn-color-white popup-box-enabled"
															role="button"
															data-popup-hash="4cc74e8d702eb862c030739d1dc1317d">
															<span className="cs-btn-text">Book Appointment</span> </a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section
							className="elementor-section elementor-top-section elementor-element elementor-element-212f26c4 elementor-section-content-middle elementor-hidden-desktop elementor-section-full_width elementor-section-height-default elementor-section-height-default"
							data-id="212f26c4" data-element_type="section"
							data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
							<div className="elementor-container elementor-column-gap-no">
								<div className="elementor-row">
									<div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-2151b475 dark-color"
										data-id="2151b475" data-element_type="column">
										<div className="elementor-column-wrap elementor-element-populated">
											<div className="elementor-widget-wrap">
												<div className="elementor-element elementor-element-5d4f0c6e elementor-widget__width-auto elementor-widget elementor-widget-cs_menu_toggle"
													data-id="5d4f0c6e" data-element_type="widget"
													data-widget_type="cs_menu_toggle.default">
													<div className="elementor-widget-container">
														<button className="menu-toggle elementor-widget-menu-toggle">
															<span className="screen-reader-text">Menu</span>
															<span className="menu-toggle-icon"></span>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-598779d2"
										data-id="598779d2" data-element_type="column">
										<div className="elementor-column-wrap elementor-element-populated">
											<div className="elementor-widget-wrap">
												<div className="elementor-element elementor-element-4ee0d62d elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-cs_logo"
													data-id="4ee0d62d" data-element_type="widget"
													data-widget_type="cs_logo.default">
													<div className="elementor-widget-container">
														<a href="">
															<img width="255" height="51"
																src="/images/logo.png"
																className="attachment-cozystay_255x9999 size-cozystay_255x9999 wp-image-184"
																alt=""
																sizes="(max-width: 255px) 100vw, 255px"/> </a>
																
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-550755d4 dark-color"
										data-id="550755d4" data-element_type="column">
										<div className="elementor-column-wrap elementor-element-populated">
											<div className="elementor-widget-wrap">
												<div className="elementor-element elementor-element-652feb45 elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
													data-id="652feb45" data-element_type="widget"
													data-widget_type="text-editor.default">
													<div className="elementor-widget-container">
														<div className="elementor-text-editor elementor-clearfix">
															<a href="#">EN</a>
															/ <a href="#">FR</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</header>
  )
}

export default StickyHeader